.markdown > img {
  max-width: 100%;
  border-width: 1px;
  border-color: #718096;
}

.markdown h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown h2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown h4 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown h5 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown h6 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.markdown p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.markdown a {
  color: #4299e1;
  text-decoration: none;
}

.markdown a:hover {
  color: #3182ce;
  text-decoration: none;
}

.markdown blockquote {
  border-color: #e2e8f0;
  border-left-width: 4px;
  font-weight: 400;
  font-style: italic;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  color: #2d3748;
  font-size: 1.125rem;
}

.markdown code {
  background-color: #e2e8f0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.markdown hr {
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 9999px;
}

.markdown ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown table {
  width: 100%;
  color: #1a202c;
  margin-bottom: 1rem;
  padding: 0;
  border-collapse: collapse;
}

.markdown table tr {
  border-top-width: 1px;
  border-color: #4a5568;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.markdown table tr:nth-child(2n) {
  background-color: #f7fafc;
}

.markdown table tr th {
  font-weight: 700;
  border-width: 1px;
  border-color: #4a5568;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

.markdown table tr th:first-child {
  margin-top: 0;
}

.markdown table tr th:last-child {
  margin-bottom: 0;
}

.markdown table tr td {
  border-width: 1px;
  border-color: #4a5568;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

.markdown table tr td:first-child {
  margin-top: 0;
}

.markdown table tr td:last-child {
  margin-bottom: 0;
}
